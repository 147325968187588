import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { hasProAccess } from 'config/plan'
import dayjs from 'dayjs'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from 'features/customerSegments/querySetForm/schema/converter'
import { InsightDrawerState, InsightFilterState, addIndexToName, chartColors, chartColorsDark } from 'features/insights/types/insight'
import { generateQuerySet } from 'features/insights/types/querySet'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { InsightDimension } from 'gen/firestore'
import { InsightService } from 'gen/proto/insight/insight_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { formatYearMonth } from 'utils/timeUtil'
import { FreePlanSkeletonText } from './components/freePlanSkeletonText'
import { InsightDrawer } from './components/insightDrawer'
import { NameCheckBoxList } from './components/nameCheckBoxList'
import { ViewType, ViewTypeToggleButton } from './components/viewTypeToggleButton'

const DEFAULT_VISIBLE_COUNT = 3
const VISUAL_PAPER_HEIGHT = '70vh'
const TABLE_ROWS_PER_PAGE = 10

type Props = {
  dimension: InsightDimension
  filter: InsightFilterState
}

type MonthlyInsightValue = {
  rank: number
  name: string
  values: {
    yearMonth: string
    customerCount: number
  }[]
  isVisible: boolean
}

export const MonthlyInsight: FC<Props> = ({ dimension, filter }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const authUser = useAuthUser()
  const { account, shop } = useAccount()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const insightService = useGrpcClient(InsightService)

  const [viewType, setViewType] = useState<ViewType>(ViewType.visual)
  const [loading, setLoading] = useState(false)
  const [insightValues, setInsightValues] = useState<MonthlyInsightValue[]>()
  const [selectedNames, setSelectedNames] = useState<string[]>([])
  const [page, setPage] = useState(0)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  const [insightDrawerState, setInsightDrawerState] = useState<InsightDrawerState>({ open: false })

  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const handleChartDataPointClick = (name: string, month: string) => {
    const queryFilters = [
      {
        filter_type: 'order_date' as const,
        operator: 'date_range' as const,
        value: {
          min_date: dayjs(month).startOf('month').format('YYYY-MM-DD'),
          max_date: dayjs(month).endOf('month').format('YYYY-MM-DD'),
        },
      },
    ]
    const segmentQuerySet = customerSegments?.find((segment) => segment.ref.id === filter.customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateQuerySet(dimension, name, queryFilters, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  useEffect(() => {
    setSelectedNames(insightValues?.slice(0, DEFAULT_VISIBLE_COUNT).map((value) => value.name) || [])
  }, [insightValues])

  useEffect(() => {
    if (loading) setPage(0) // Reset page
  }, [loading])

  useEffect(() => {
    const fetchData = async () => {
      if (!filter.startDate || !filter.endDate) return
      setLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp = await insightService.getMonthlyReport(
          {
            customerSegmentId: filter.customerSegmentId || '',
            dimension: dimension,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        setInsightValues(
          resp.values.map((v, i) => ({
            rank: i + 1,
            name: v.name,
            values: v.monthlyValues.map((v) => ({
              yearMonth: formatYearMonth(dayjs(v.yearMonth), i18n.language),
              customerCount: Number(v.customerCount),
            })),
            isVisible: hasProAccess(shop) || i < DEFAULT_VISIBLE_COUNT,
          }))
        )
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension, filter])

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: insightValues
      ?.filter(({ isVisible }) => isVisible)
      .flatMap(({ name, values }) =>
        values.map(({ yearMonth, customerCount }) => ({
          name,
          yearMonth,
          customerCount,
        }))
      ),
    headers: [
      t('features.insights.detail.name'),
      t('features.insights.detail.monthlyInsight.yearMonth'),
      t('features.insights.detail.customerCount'),
    ],
    fileName: t('features.insights.detail.monthlyInsight.downloadFileName', { dimension: dimension }),
    page: 'insight_' + dimension,
  })

  return (
    <>
      <Box display='flex' justifyContent='flex-end' alignItems='center' marginTop='-52px' marginBottom='16px'>
        <ViewTypeToggleButton viewType={viewType} setViewType={setViewType} />

        <Tooltip title={t('features.insights.detail.download')} placement='top'>
          <span>
            <IconButton
              disabled={!insightValues || insightValues.length === 0 || downloadLoading}
              onClick={() => {
                if (hasProAccess(shop)) {
                  handleDownload()
                  return
                }
                setUpgradeRecommendDialogOpen(true)
              }}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      {viewType === ViewType.visual && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {loading || !insightValues ? (
              <Paper
                variant='outlined'
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: VISUAL_PAPER_HEIGHT, borderRadius: '12px' }}
              >
                <CircularProgress />
              </Paper>
            ) : insightValues.length === 0 ? (
              <Paper
                variant='outlined'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: VISUAL_PAPER_HEIGHT,
                  borderRadius: '12px',
                }}
              >
                <EmptyState title={t('features.insights.detail.noData')} />
              </Paper>
            ) : (
              <Paper variant='outlined' sx={{ padding: '20px', borderRadius: '12px', height: VISUAL_PAPER_HEIGHT }}>
                <Chart
                  type='line'
                  height='100%'
                  series={insightValues
                    .filter((value) => selectedNames.includes(value.name))
                    .map((value) => ({
                      name: value.name,
                      data: value.values.map((v) => ({
                        x: v.yearMonth,
                        y: v.customerCount,
                      })),
                    }))}
                  options={{
                    xaxis: {
                      type: 'category',
                      categories: insightValues[0].values.map((v) => v.yearMonth),
                      labels: { style: { colors: theme.palette.text.primary } },
                      tickAmount: 18,
                    },
                    yaxis: {
                      title: {
                        text: t('features.insights.detail.customerCount'),
                        style: { color: theme.palette.text.primary },
                      },
                      labels: { style: { colors: theme.palette.text.primary } },
                    },
                    chart: {
                      zoom: { enabled: false },
                      toolbar: { show: false },
                      events: {
                        markerClick: (_, __, config) => {
                          const selectedName = insightValues[config.seriesIndex as number].name
                          const selectedMonth = insightValues[config.seriesIndex as number].values[config.dataPointIndex].yearMonth
                          handleChartDataPointClick(selectedName, selectedMonth)
                          document.body.style.cursor = 'default'
                        },
                        mouseMove: () => {
                          document.body.style.cursor = 'pointer'
                        },
                      },
                    },
                    tooltip: {
                      shared: false,
                      theme: theme.palette.mode,
                      y: { formatter: (val) => t('features.insights.detail.customers', { count: val }) },
                    },
                    legend: { labels: { colors: theme.palette.text.primary } },
                    stroke: { curve: 'smooth', width: 3 },
                    markers: { size: 0 },
                    colors: theme.palette.mode === 'dark' ? chartColorsDark : chartColors,
                  }}
                />
              </Paper>
            )}
          </Grid>
          <Grid item xs={3}>
            <NameCheckBoxList
              loading={loading}
              items={insightValues?.map((value) => ({ name: value.name, isVisible: value.isVisible })) || []}
              selectedNames={selectedNames}
              setSelectedNames={setSelectedNames}
              height={VISUAL_PAPER_HEIGHT}
            />
          </Grid>
        </Grid>
      )}

      {viewType === ViewType.table && (
        <Paper variant='outlined' sx={{ padding: '20px 36px', borderRadius: '12px' }}>
          {loading || !insightValues ? (
            <SkeletonTable columnCount={12} rowCount={10} />
          ) : insightValues.length === 0 ? (
            <EmptyState title={t('features.insights.detail.noData')} />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell>{t('features.insights.detail.name')}</TableCell>
                    {insightValues[0].values.map((v, i) => (
                      <TableCell key={i} align='center'>
                        {v.yearMonth}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {insightValues.slice(page * TABLE_ROWS_PER_PAGE, page * TABLE_ROWS_PER_PAGE + TABLE_ROWS_PER_PAGE).map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell
                        component='th'
                        scope='row'
                        style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        <Tooltip title={row.name} placement='top'>
                          <span>{addIndexToName(row.name, row.rank)}</span>
                        </Tooltip>
                      </TableCell>
                      {row.values.map((v, index) => (
                        <TableCell key={index} align='center'>
                          {row.isVisible ? (
                            <Tooltip title={t('features.insights.detail.customers', { count: v.customerCount })} placement='top'>
                              <span>{v.customerCount}</span>
                            </Tooltip>
                          ) : (
                            <FreePlanSkeletonText />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {insightValues && insightValues.length > 0 && (
            <Box display='flex' justifyContent='center'>
              <Pagination
                shape='rounded'
                count={insightValues ? Math.ceil(insightValues.length / TABLE_ROWS_PER_PAGE) : 0}
                page={page + 1}
                onChange={(_, v) => setPage(v - 1)}
                sx={{ marginY: '20px' }}
              />
            </Box>
          )}
        </Paper>
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog open={upgradeRecommendDialogOpen} handleClose={() => setUpgradeRecommendDialogOpen(false)} referrer='feature' />
      )}

      <InsightDrawer
        open={insightDrawerState.open}
        handleOpen={() => setInsightDrawerState({ open: true })}
        handleClose={() => setInsightDrawerState({ open: false })}
        querySet={insightDrawerState.querySet}
      />
    </>
  )
}
