import { Box, Card, CardActionArea, CardContent, Chip, Grid, Skeleton, Tooltip, Typography, useTheme } from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { getImagePath } from 'utils/imageUtil'
import { formatYearMonth } from 'utils/timeUtil'
import { ReportTypeIcon } from './components/reportTypeIcon'
import { InsightCategory, insightDimensions } from './types/insightDimensions'

export const Insights = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const customerSegmentId = searchParams.get('customer_segment_id') || ''

  const [category, setCategory] = useState<InsightCategory>(InsightCategory.all)

  const filteredInsights = useMemo(() => {
    if (category === InsightCategory.all) return insightDimensions
    return insightDimensions.filter((dimension) => dimension.category.includes(category))
  }, [category])

  return (
    <>
      <PageHeader title={t('features.insights.title')} marginBottom='24px' guideType='Insights' />

      <Box marginBottom='36px'>
        <Grid container spacing={3} alignItems='stretch'>
          {[
            { key: 'monthly', path: AUTHORIZED_ROUTE.COHORTS_MONTHLY },
            { key: 'nth_order', path: AUTHORIZED_ROUTE.COHORTS_NTH_ORDER },
          ].map((item, index) => (
            <Grid key={index} item sm={12} md={6} sx={{ width: '100%' }}>
              <Card
                variant='outlined'
                sx={{
                  borderRadius: '12px',
                  height: '100%',
                  '&:hover': {
                    boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardActionArea
                  onClick={() => {
                    const to =
                      `${item.path}?start_year_month=${formatYearMonth(dayjs().subtract(1, 'year'))}&end_year_month=${formatYearMonth(dayjs())}` +
                      (customerSegmentId.length !== 0 ? `&customer_segment_id=${customerSegmentId}` : '')
                    navigate(to)
                  }}
                  sx={{ paddingLeft: '8px', height: '100%' }}
                >
                  <CardContent>
                    <Box display='flex' justifyContent='space-between' alignItems='center' gap={2}>
                      <Box>
                        <Typography marginBottom='4px'>{t('features.insights.cohortTitle', { context: item.key })}</Typography>
                        <Typography fontSize='13px' color='text.secondary'>
                          {t('features.insights.cohortDesc', { context: item.key })}
                        </Typography>
                      </Box>
                      <Box
                        component='img'
                        src={theme.palette.mode === 'dark' ? getImagePath(`insights/${item.key}_dark.svg`) : getImagePath(`insights/${item.key}.svg`)}
                        alt={item.key}
                        width='100px'
                      />
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='24px'>
        <Box>
          {Object.values(InsightCategory).map((c) => (
            <Chip
              key={c}
              size='small'
              label={t('features.insights.category', { context: c })}
              color={c === category ? 'primary' : 'default'}
              onClick={() => setCategory(c)}
              sx={{ cursor: 'pointer', borderRadius: '6px', marginRight: '8px' }}
            />
          ))}
        </Box>
      </Box>

      <Box>
        <Grid container spacing={3} alignItems='stretch'>
          {!filteredInsights
            ? Array.from(new Array(4)).map((_, i) => (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                  <Card sx={{ borderRadius: '12px' }}>
                    <CardActionArea>
                      <Skeleton variant='rectangular' height={120} animation='wave' />
                      <CardContent>
                        <Skeleton width='60%' height={30} animation='wave' />
                        <Skeleton width='90%' height={20} animation='wave' />
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            : filteredInsights.map((row) => (
                <Grid key={row.name} item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    variant='outlined'
                    sx={{
                      borderRadius: '12px',
                      height: '100%',
                      position: 'relative',
                      '&:hover': {
                        boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
                      },
                      '&:hover .showOnHover': {
                        visibility: 'visible',
                      },
                    }}
                  >
                    <CardActionArea
                      onClick={() => {
                        const to =
                          generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
                            id: row.name,
                          }) + (customerSegmentId ? `?customer_segment_id=${customerSegmentId}` : '')
                        navigate(to)
                      }}
                      sx={{ height: '100%', padding: '2px 6px' }}
                    >
                      <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <Typography fontSize='24px' marginBottom='8px'>
                          {extractIconFromTextWithIcon(t('features.insights.dimension', { context: row.name }))}
                        </Typography>
                        <Box marginBottom='16px'>
                          <Typography
                            fontSize='14px'
                            onClick={() => {
                              const to =
                                generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
                                  id: row.name,
                                }) + (customerSegmentId.length !== 0 ? `?customer_segment_id=${customerSegmentId}` : '')
                              navigate(to)
                            }}
                            sx={{ marginBottom: '2px', cursor: 'pointer', '&:hover': { color: (theme) => theme.palette.text.secondary } }}
                          >
                            {extractTextFromTextWithIcon(t('features.insights.dimension', { context: row.name }))}
                          </Typography>
                          <Typography fontSize='13px' color='text.secondary'>
                            {t(`features.insights.questions.${row.name}_${row.reportTypes[0]}`)}
                          </Typography>
                        </Box>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                          <Typography
                            fontSize='11px'
                            marginRight='8px'
                            sx={{
                              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
                              padding: '2px 6px',
                              borderRadius: '2px',
                            }}
                          >
                            {t('features.insights.category', { context: row.category })}
                          </Typography>
                          <Box display='flex'>
                            {row.reportTypes.map((type) => (
                              <Tooltip key={type} title={t('features.insights.detail.reportType', { context: type })}>
                                <Box display='flex' alignItems='center'>
                                  <ReportTypeIcon reportType={type} color='action' sx={{ fontSize: '16px', marginRight: '4px' }} />
                                </Box>
                              </Tooltip>
                            ))}
                          </Box>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
        </Grid>
      </Box>
    </>
  )
}
