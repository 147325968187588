import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { hasProAccess } from 'config/plan'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from 'features/customerSegments/querySetForm/schema/converter'
import { InsightDrawerState, InsightFilterState, addIndexToName, chartColors, chartColorsDark } from 'features/insights/types/insight'
import { generateQuerySet } from 'features/insights/types/querySet'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { InsightDimension } from 'gen/firestore'
import { InsightService } from 'gen/proto/insight/insight_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useSort } from 'hooks/useSort'
import { FC, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { FreePlanSkeletonText } from './components/freePlanSkeletonText'
import { InsightDrawer } from './components/insightDrawer'
import { NameCheckBoxList } from './components/nameCheckBoxList'
import { ViewType, ViewTypeToggleButton } from './components/viewTypeToggleButton'

const DEFAULT_VISIBLE_COUNT = 3
const VISUAL_PAPER_HEIGHT = '70vh'
const TABLE_ROWS_PER_PAGE = 10

type Props = {
  dimension: InsightDimension
  filter: InsightFilterState
}

type PopularityInsightValue = {
  rank: number
  name: string
  customerCount: number
  ratio: number
  isVisible: boolean
}

export const PopularityInsight: FC<Props> = ({ dimension, filter }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const authUser = useAuthUser()
  const { account, shop } = useAccount()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const insightService = useGrpcClient(InsightService)

  const [viewType, setViewType] = useState<ViewType>(ViewType.visual)
  const [loading, setLoading] = useState(false)
  const [insightValues, setInsightValues] = useState<PopularityInsightValue[]>()
  const [selectedNames, setSelectedNames] = useState<string[]>([])
  const [page, setPage] = useState(0)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  const [insightDrawerState, setInsightDrawerState] = useState<InsightDrawerState>({ open: false })

  const { sortedData: sortedInsightValues, sort, toggleSort } = useSort<PopularityInsightValue>(insightValues || [])

  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const handleChartDataPointClick = (name: string) => {
    const queryFilters = [
      { filter_type: 'order_date' as const, operator: 'date_range' as const, value: { min_date: filter.startDate, max_date: filter.endDate } },
    ]
    const segmentQuerySet = customerSegments?.find((segment) => segment.ref.id === filter.customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateQuerySet(dimension, name, queryFilters, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  useEffect(() => {
    setSelectedNames(insightValues?.slice(0, DEFAULT_VISIBLE_COUNT).map((value) => value.name) || [])
  }, [insightValues])

  useEffect(() => {
    if (loading) setPage(0) // Reset page
  }, [loading])

  useEffect(() => {
    const fetchData = async () => {
      if (!filter.startDate || !filter.endDate) return
      setLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp = await insightService.getPopularityReport(
          {
            customerSegmentId: filter.customerSegmentId || '',
            dimension: dimension,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        setInsightValues(
          resp.values.map((v, i) => ({
            rank: i + 1,
            name: v.name,
            customerCount: Number(v.customerCount),
            ratio: Number((v.ratio * 100).toFixed(2)),
            isVisible: hasProAccess(shop) || i < DEFAULT_VISIBLE_COUNT,
          }))
        )
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension, filter])

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: insightValues?.filter(({ isVisible }) => isVisible).map(({ name, customerCount, ratio }) => ({ name, customerCount, ratio })),
    headers: [t('features.insights.detail.name'), t('features.insights.detail.customerCount'), t('features.insights.detail.popularityInsight.ratio')],
    fileName: t('features.insights.detail.popularityInsight.downloadFileName', { dimension: dimension }),
    page: 'insight_' + dimension,
  })

  return (
    <>
      <Box display='flex' justifyContent='flex-end' alignItems='center' marginTop='-52px' marginBottom='16px'>
        <ViewTypeToggleButton viewType={viewType} setViewType={setViewType} />

        <Tooltip title={t('features.insights.detail.download')} placement='top'>
          <span>
            <IconButton
              disabled={!insightValues || insightValues.length === 0 || downloadLoading}
              onClick={() => {
                if (hasProAccess(shop)) {
                  handleDownload()
                  return
                }
                setUpgradeRecommendDialogOpen(true)
              }}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      {viewType === ViewType.visual && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {loading || !insightValues ? (
              <Paper
                variant='outlined'
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: VISUAL_PAPER_HEIGHT, borderRadius: '12px' }}
              >
                <CircularProgress />
              </Paper>
            ) : insightValues.length === 0 ? (
              <Paper
                variant='outlined'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: VISUAL_PAPER_HEIGHT,
                  borderRadius: '12px',
                }}
              >
                <EmptyState title={t('features.insights.detail.noData')} />
              </Paper>
            ) : (
              <Paper variant='outlined' sx={{ padding: '20px', borderRadius: '12px', height: VISUAL_PAPER_HEIGHT }}>
                <Chart
                  type='radialBar'
                  height='100%'
                  series={insightValues.filter((value) => selectedNames.includes(value.name)).map((value) => value.ratio)}
                  options={{
                    plotOptions: {
                      radialBar: {
                        dataLabels: {
                          value: {
                            show: true,
                            fontSize: '28px',
                            fontWeight: 'bold',
                            color: theme.palette.text.primary,
                          },
                          total: {
                            show: true,
                            label: 'Total Customers',
                            formatter: () => {
                              return ((insightValues[0].customerCount / insightValues[0].ratio) * 100).toFixed(0)
                            },
                            color: theme.palette.text.primary,
                          },
                        },
                      },
                    },
                    chart: {
                      events: {
                        dataPointSelection: (_, __, config) => {
                          const selectedName = insightValues.filter((value) => selectedNames.includes(value.name))[config.dataPointIndex].name
                          handleChartDataPointClick(selectedName)
                          document.body.style.cursor = 'default'
                        },
                        dataPointMouseEnter: () => {
                          document.body.style.cursor = 'pointer'
                        },
                        dataPointMouseLeave: () => {
                          document.body.style.cursor = 'default'
                        },
                      },
                    },
                    legend: {
                      show: true,
                      position: 'bottom',
                      labels: { colors: theme.palette.text.primary },
                    },
                    stroke: { lineCap: 'round' },
                    labels: insightValues.filter((value) => selectedNames.includes(value.name)).map((value) => value.name),
                    colors: theme.palette.mode === 'dark' ? chartColorsDark : chartColors,
                  }}
                />
              </Paper>
            )}
          </Grid>
          <Grid item xs={3}>
            <NameCheckBoxList
              loading={loading}
              items={insightValues?.map((value) => ({ name: value.name, isVisible: value.isVisible })) || []}
              selectedNames={selectedNames}
              setSelectedNames={setSelectedNames}
              height={VISUAL_PAPER_HEIGHT}
            />
          </Grid>
        </Grid>
      )}

      {viewType === ViewType.table && (
        <Paper variant='outlined' sx={{ padding: '20px 36px', borderRadius: '12px' }}>
          {loading || !insightValues ? (
            <SkeletonTable columnCount={3} rowCount={10} />
          ) : insightValues.length === 0 ? (
            <EmptyState title={t('features.insights.detail.noData')} />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell>{t('features.insights.detail.name')}</TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'customerCount'}
                        direction={sort.target === 'customerCount' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('customerCount')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.customerCount')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>{t('features.insights.detail.popularityInsight.ratio')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedInsightValues.slice(page * TABLE_ROWS_PER_PAGE, page * TABLE_ROWS_PER_PAGE + TABLE_ROWS_PER_PAGE).map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell
                        component='th'
                        scope='row'
                        style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        <Tooltip title={row.name} placement='top'>
                          <Typography component='span' fontSize='14px'>
                            {addIndexToName(row.name, row.rank)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>{row.isVisible ? row.customerCount : <FreePlanSkeletonText />}</TableCell>
                      <TableCell align='center'>
                        {row.isVisible ? (
                          <Box display='flex' alignItems='center'>
                            <Box width='100%' mr={1}>
                              <LinearProgress variant='determinate' value={row.ratio} />
                            </Box>
                            <Box minWidth={35}>
                              <Typography variant='body2' color='textSecondary'>{`${
                                row.ratio < 1 ? row.ratio.toFixed(1) : Math.ceil(row.ratio)
                              }%`}</Typography>
                            </Box>
                          </Box>
                        ) : (
                          <FreePlanSkeletonText />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {insightValues && insightValues.length > 0 && (
            <Box display='flex' justifyContent='center'>
              <Pagination
                shape='rounded'
                count={insightValues ? Math.ceil(insightValues.length / TABLE_ROWS_PER_PAGE) : 0}
                page={page + 1}
                onChange={(_, v) => setPage(v - 1)}
                sx={{ marginY: '20px' }}
              />
            </Box>
          )}
        </Paper>
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog open={upgradeRecommendDialogOpen} handleClose={() => setUpgradeRecommendDialogOpen(false)} referrer='feature' />
      )}

      <InsightDrawer
        open={insightDrawerState.open}
        handleOpen={() => setInsightDrawerState({ open: true })}
        handleClose={() => setInsightDrawerState({ open: false })}
        querySet={insightDrawerState.querySet}
      />
    </>
  )
}
