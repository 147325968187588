import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import RestoreIcon from '@mui/icons-material/Restore'
import { Box, FormControl, IconButton, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { MetricsItems } from 'components/metricsItems'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { hasProAccess } from 'config/plan'
import dayjs from 'dayjs'
import { MetricsHistoryChart } from 'features/customerSegments/pages/detail/components/components/metricsHistoryChart'
import { MetricsIndexToggleButton } from 'features/customerSegments/pages/detail/components/components/metricsIndexToggleButton'
import { PastDataReproductionDialog } from 'features/customerSegments/pages/detail/components/components/pastDataReprocutionDialog'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { useAccount } from 'hooks/useAccount'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'

type Props = {
  customerSegment: CustomerSegmentState | undefined
}

enum FilterOption {
  last30Days = 'last30Days',
  last60Days = 'last60Days',
  last90Days = 'last90Days',
  last180Days = 'last180Days',
  last1year = 'last1Year',
  last2years = 'last2Years',
  last3years = 'last3Years',
}

export enum MetricsIndex {
  count = 'count',
  repeaterRate = 'repeaterRate',
  ltv = 'ltv',
  avgOrderValue = 'avgOrderValue',
  avgOrderCount = 'avgOrderCount',
  avgDaysBetweenOrders = 'avgDaysBetweenOrders',
}

export const Metrics: FC<Props> = ({ customerSegment }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()

  const minDate = '2021-01-01' // temp
  const maxDate = formatDate(dayjs().add(1, 'day')) // tomorrow
  const [filterValue, setFilterValue] = useState(FilterOption.last30Days)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false)
  const [metricsIndex, setMetricsIndex] = useState<MetricsIndex>(MetricsIndex.count)

  const handleFilterValue = (v: FilterOption) => {
    if (hasProAccess(shop)) {
      setFilterValue(v)
      return
    }
    setUpgradeRecommendDialogOpen(true)
    setFilterValue(FilterOption.last30Days)
  }

  const filteredMetricsHistory = useMemo(() => {
    if (!customerSegment || !customerSegment.metricsHistory) return []
    const daysMap = { last30Days: 30, last60Days: 60, last90Days: 90, last180Days: 180, last1Year: 365, last2Years: 730, last3Years: 1095 }
    const newStartDate = dayjs().subtract(daysMap[filterValue] || 0, 'day')
    const startDate = dayjs(minDate).isAfter(newStartDate) ? minDate : formatDate(newStartDate)
    return customerSegment.metricsHistory.filter((mh) => {
      return dayjs(mh.date).isAfter(dayjs(startDate)) && dayjs(mh.date).isBefore(dayjs(maxDate))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegment, filterValue])

  const metricsIndexLabel = {
    count: t('common.customerMetrics.title', { context: 'count' }),
    repeaterRate: t('common.customerMetrics.title', { context: 'repeaterRate' }),
    ltv: t('common.customerMetrics.title', { context: 'ltv' }),
    avgOrderValue: t('common.customerMetrics.title', { context: 'avgOrderValue' }),
    avgOrderCount: t('common.customerMetrics.title', { context: 'avgOrderCount' }),
    avgDaysBetweenOrders: t('common.customerMetrics.title', { context: 'avgDaysBetweenOrders' }),
  }

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: filteredMetricsHistory
      ? filteredMetricsHistory.map((item) => ({
          date: item.date,
          ...item.metrics,
        }))
      : [],
    headers: [
      t('features.customerSegments.detail.metrics.date'),
      t('common.customerMetrics.title', { context: 'count' }),
      t('common.customerMetrics.title', { context: 'repeaterRate' }),
      t('common.customerMetrics.title', { context: 'ltv' }),
      t('common.customerMetrics.title', { context: 'avgOrderValue' }),
      t('common.customerMetrics.title', { context: 'avgOrderCount' }),
      t('common.customerMetrics.title', { context: 'avgDaysBetweenOrders' }),
    ],
    fileName: t('features.customerSegments.detail.metrics.downloadFileName', {
      name: customerSegment?.name,
      date: formatDate(dayjs(), i18n.language),
    }),
    page: 'customerSegment_metricsHistory',
  })

  return (
    <>
      <Box marginBottom='24px'>
        <MetricsItems metrics={customerSegment?.metrics} type='grid' onClick={(key) => setMetricsIndex(key as MetricsIndex)} />
      </Box>

      <Paper sx={{ padding: '32px', borderRadius: '12px' }} variant='outlined'>
        <Typography variant='h3' sx={{ fontSize: '16px', fontWeight: 600, marginBottom: '16px' }}>
          {t('features.customerSegments.detail.metrics.title')}
        </Typography>

        {filteredMetricsHistory && (
          <Box>
            {filteredMetricsHistory.length <= 1 ? (
              <EmptyState title={t('features.customerSegments.detail.metrics.noData')} />
            ) : (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                  <Box display='flex'>
                    {Object.entries(metricsIndexLabel).map(([index, label]) => (
                      <MetricsIndexToggleButton<MetricsIndex>
                        key={index}
                        index={MetricsIndex[index as keyof typeof MetricsIndex]}
                        label={label}
                        selected={metricsIndex === MetricsIndex[index as keyof typeof MetricsIndex]}
                        setMetricsIndex={setMetricsIndex}
                      />
                    ))}
                  </Box>

                  <Box>
                    <FormControl>
                      <Select
                        size='small'
                        disabled={!filteredMetricsHistory}
                        value={filterValue}
                        onChange={(e) => {
                          const newValue = e.target.value as FilterOption
                          handleFilterValue(newValue)
                        }}
                        sx={{ marginRight: '12px' }}
                      >
                        {Object.values(FilterOption).map((value) => (
                          <MenuItem key={value} value={value}>
                            {t('features.customerSegments.detail.metrics.filter', { context: value })}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Tooltip title={t('features.customerSegments.detail.metrics.restore')} placement='top'>
                      <span>
                        <IconButton
                          color='inherit'
                          disabled={!customerSegment || !customerSegment.metricsHistory}
                          onClick={() => {
                            if (hasProAccess(shop)) {
                              setRestoreDialogOpen(true)
                              return
                            }
                            setUpgradeRecommendDialogOpen(true)
                          }}
                        >
                          <RestoreIcon />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title={t('features.customerSegments.detail.metrics.download')} placement='top'>
                      <span>
                        <IconButton
                          color='inherit'
                          disabled={!customerSegment || !customerSegment.metricsHistory || downloadLoading}
                          onClick={() => {
                            if (hasProAccess(shop)) {
                              handleDownload()
                              return
                            }
                            setUpgradeRecommendDialogOpen(true)
                          }}
                        >
                          <FileDownloadOutlinedIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>

                <Box>
                  <MetricsHistoryChart metricsHistory={filteredMetricsHistory} index={metricsIndex} indexLabel={metricsIndexLabel[metricsIndex]} />
                </Box>
              </>
            )}
          </Box>
        )}
      </Paper>

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentMetricsHistory'
        />
      )}

      {restoreDialogOpen && <PastDataReproductionDialog open={restoreDialogOpen} handleClose={() => setRestoreDialogOpen(false)} />}
    </>
  )
}
