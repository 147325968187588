import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import { SkeletonTable } from 'components/skeletonTable'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { hasProAccess } from 'config/plan'
import dayjs from 'dayjs'
import { orderBy, query } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCurrency } from 'hooks/useCurrency'
import { useCollection } from 'hooks/useFirestoreData'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { formatDate, formatYearMonth } from 'utils/timeUtil'
import { useNthOrderCohort } from '../hooks/useNthOrderCohort'
import { CohortFilterState, defaultEndYearMonth, defaultStartYearMonth, isValidYearMonthFormat } from '../types/cohortFilter'
import { CohortFilter } from './components/cohortFilter'
import { EmptyState } from 'components/emptyState'

type MetricsType = 'customerCount' | 'repeatRate' | 'avgOrderValue' | 'avgDaysBetweenOrders'

export const NthOrderCohort = () => {
  const { t, i18n } = useTranslation()
  const { formatCurrency } = useCurrency()
  const navigate = useNavigate()
  const theme = useTheme()
  const { account, shop } = useAccount()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const [filterOpen, setFilterOpen] = useState(false)
  const [cohortFilter, setCohortFilter] = useState<CohortFilterState>()
  const [metricsIndex, setMetricsIndex] = useState<MetricsType>('customerCount')
  const { nthOrderCohortSummary, loading } = useNthOrderCohort(cohortFilter)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  useEffect(() => {
    const startYearMonth = searchParams.get('start_year_month')
    const endYearMonth = searchParams.get('end_year_month')

    const isStartYearMonthValid = startYearMonth && isValidYearMonthFormat(startYearMonth)
    const isEndYearMonthValid = endYearMonth && isValidYearMonthFormat(endYearMonth)

    if (!isStartYearMonthValid || !isEndYearMonthValid) {
      const searchParams = `?start_year_month=${defaultStartYearMonth}&end_year_month=${defaultEndYearMonth}`
      const to = `${AUTHORIZED_ROUTE.COHORTS_MONTHLY}${searchParams}`
      navigate(to)
      return
    }

    setCohortFilter({
      startYearMonth: startYearMonth || defaultStartYearMonth,
      endYearMonth: endYearMonth || defaultEndYearMonth,
      customerSegmentId: searchParams.get('customer_segment_id') || '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const getStepValue = (step: number): string => {
    return `F${step}`
  }

  const getMetricsValue = (metrics: number): string => {
    switch (metricsIndex) {
      case 'customerCount':
        return metrics.toLocaleString()
      case 'repeatRate':
        return `${(metrics * 100).toFixed(1)}%`
      case 'avgOrderValue':
        return formatCurrency(metrics) || ''
      case 'avgDaysBetweenOrders':
        // Do not display the avgDaysBetweenOrders of the first purchase because it does not exist
        return metrics === -1 ? '-' : `${metrics.toFixed(0)}`
    }
  }

  const maxMetricValue = nthOrderCohortSummary
    ? Math.max(...nthOrderCohortSummary.nthResult.flatMap((nr) => nr.stepAndMetricsList.map((sm) => sm[metricsIndex])))
    : 0

  const getBackgroundColor = (step: number, value: number): string => {
    if (value === -1) return 'none'
    const scale = value / maxMetricValue
    if (scale >= 0.8) return `${theme.palette.primary.main}99` // 60% opacity
    if (scale >= 0.6) return `${theme.palette.primary.main}66` // 40% opacity
    if (scale >= 0.4) return `${theme.palette.primary.main}4D` // 30% opacity
    if (scale >= 0.2) return `${theme.palette.primary.main}33` // 20% opacity
    return `${theme.palette.primary.main}1A` // 10% opacity
  }

  const getCustomerSegmentName = useMemo(
    () => (id: string) => {
      const customerSegment = customerSegments?.find((cs) => cs.ref.id === id)
      if (!customerSegment) return ''
      return customerSegment.name.length > 32 ? `${customerSegment.name.substring(0, 29)}...` : customerSegment.name
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerSegments, cohortFilter?.customerSegmentId]
  )

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data:
      nthOrderCohortSummary &&
      nthOrderCohortSummary.nthResult.flatMap((nr) => {
        return nr.stepAndMetricsList.map((sm) => {
          return {
            firstOrderYearMonth: nr.firstOrderYearMonth,
            step: getStepValue(sm.step),
            customerCount: sm.customerCount,
            repeatRate: sm.repeatRate.toFixed(3),
            avgOrderValue: sm.avgOrderValue,
            avgDaysBetweenOrders: sm.avgDaysBetweenOrders === -1 ? '' : sm.avgDaysBetweenOrders.toFixed(1),
          }
        })
      }),
    headers: [
      t('features.cohorts.nthOrderCohort.firstOrderYearMonth'),
      t('features.cohorts.nthOrderCohort.step'),
      t('features.cohorts.nthOrderCohort.customerCount'),
      t('features.cohorts.nthOrderCohort.repeatRate'),
      t('features.cohorts.nthOrderCohort.avgOrderValue'),
      t('features.cohorts.nthOrderCohort.avgDaysBetweenOrders'),
    ],
    fileName: t('features.cohorts.nthOrderCohort.downloadFileName', { date: formatDate(dayjs(), i18n.language) }),
    page: 'cohort_nthOrder',
  })

  return (
    <>
      <PageHeader title={t('features.cohorts.nthOrderCohort.title')} marginBottom='32px' guideType='NthOrderCohort' />

      <Box display='flex' justifyContent='space-between' marginBottom='20px'>
        <Box display='flex' alignItems='center'>
          {cohortFilter && (
            <Button
              variant='outlined'
              size='small'
              startIcon={<CalendarMonthOutlinedIcon fontSize='small' />}
              onClick={() => setFilterOpen(!filterOpen)}
            >
              {formatYearMonth(dayjs(cohortFilter.startYearMonth), i18n.language)} -{' '}
              {formatYearMonth(dayjs(cohortFilter.endYearMonth), i18n.language)}
            </Button>
          )}

          {cohortFilter && (
            <Button
              variant='outlined'
              size='small'
              onClick={() => setFilterOpen(!filterOpen)}
              sx={{ marginLeft: '12px' }}
              startIcon={!cohortFilter.customerSegmentId ? <PeopleOutlinedIcon fontSize='small' /> : null}
            >
              {cohortFilter.customerSegmentId
                ? getCustomerSegmentName(cohortFilter.customerSegmentId)
                : t('features.cohorts.nthOrderCohort.allCustomers')}
            </Button>
          )}

          <ToggleButtonGroup
            value={metricsIndex}
            color='primary'
            exclusive
            size='small'
            onChange={(_, v) => {
              if (v === null) return
              setMetricsIndex(v as MetricsType)
            }}
            sx={{
              marginLeft: '20px',
              '& .MuiToggleButtonGroup-grouped': {
                padding: '4px 12px',
              },
            }}
          >
            <ToggleButton value='customerCount'>{t('features.cohorts.nthOrderCohort.customerCount')}</ToggleButton>
            <ToggleButton value='repeatRate'>{t('features.cohorts.nthOrderCohort.repeatRate')}</ToggleButton>
            <ToggleButton value='avgOrderValue'>{t('features.cohorts.nthOrderCohort.avgOrderValue')}</ToggleButton>
            <ToggleButton value='avgDaysBetweenOrders'>{t('features.cohorts.nthOrderCohort.avgDaysBetweenOrders')}</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Tooltip title={t('features.cohorts.nthOrderCohort.download')} placement='top'>
          <span>
            <IconButton
              color='inherit'
              disabled={downloadLoading || !nthOrderCohortSummary || nthOrderCohortSummary.avgResult.length === 0}
              onClick={() => {
                if (hasProAccess(shop)) {
                  handleDownload()
                  return
                }
                setUpgradeRecommendDialogOpen(true)
              }}
              sx={{ marginRight: '18px' }}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      <Collapse in={filterOpen}>
        {cohortFilter && (
          <Box marginBottom='20px'>
            <CohortFilter
              cohortFilter={cohortFilter}
              setCohortFilter={(filter) => {
                setCohortFilter(filter)
                setFilterOpen(false)
              }}
              pageURL={AUTHORIZED_ROUTE.COHORTS_NTH_ORDER}
            />
          </Box>
        )}
      </Collapse>

      {loading && <SkeletonTable rowCount={11} columnCount={14} />}
      {!loading && nthOrderCohortSummary && nthOrderCohortSummary.avgResult.length === 0 ? (
        <EmptyState title={t('features.cohorts.nthOrderCohort.lengthZero')} />
      ) : (
        !loading &&
        nthOrderCohortSummary && (
          <TableContainer>
            <Table
              size='small'
              sx={{
                '& .MuiTableCell-sizeSmall': {
                  padding: '14px',
                },
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    '& .MuiTableCell-head': {
                      color: (theme) => theme.palette.text.secondary,
                      fontSize: '13px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                >
                  <TableCell sx={{ minWidth: '140px' }}>{t('features.cohorts.nthOrderCohort.firstOrderYearMonth')}</TableCell>
                  {nthOrderCohortSummary.avgResult.map((ar) => (
                    <TableCell align='center' key={`nthOrderCohort-${ar.step}`}>
                      {getStepValue(ar.step)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {nthOrderCohortSummary.nthResult.map((nr) => (
                  <TableRow key={`nthOrderCohort-${nr.firstOrderYearMonth}`}>
                    <TableCell
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                      }}
                    >
                      {nr.firstOrderYearMonth}
                    </TableCell>
                    {nr.stepAndMetricsList.map((sm) => (
                      <TableCell
                        align='center'
                        key={`nthOrderCohort-${nr.firstOrderYearMonth}-${sm.step}`}
                        sx={{
                          backgroundColor: getBackgroundColor(sm.step, sm[metricsIndex]),
                          width: '8%',
                        }}
                      >
                        {getMetricsValue(sm[metricsIndex])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                <TableRow sx={{ backgroundColor: (theme) => theme.palette.action.hover }}>
                  <TableCell
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      width: '8%',
                    }}
                  >
                    {t('features.cohorts.nthOrderCohort.all')}
                  </TableCell>
                  {nthOrderCohortSummary.avgResult.map((ar) => (
                    <TableCell
                      align='center'
                      key={`nthOrderCohort-avg-${ar.step}`}
                      sx={{
                        backgroundColor: getBackgroundColor(ar.step, ar[metricsIndex]),
                        width: '8%',
                      }}
                    >
                      {getMetricsValue(ar[metricsIndex])}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='nthOrderCohort'
        />
      )}
    </>
  )
}
