import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import SearchIcon from '@mui/icons-material/Search'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import {
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { MetricsTooltip } from 'components/metricsTooltip'
import { PageHeader } from 'components/pageHeader'
import { SkeletonTable } from 'components/skeletonTable'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { hasProAccess } from 'config/plan'
import dayjs from 'dayjs'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { orderBy, query } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCollectionSubscription } from 'hooks/useFirestoreData'
import useLocalStorage from 'hooks/useLocalStrage'
import { NestedKeyOf, sortType, useSort } from 'hooks/useSort'
import { FC, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractTextFromTextWithIcon } from 'utils/iconUtil'
import { formatDate } from 'utils/timeUtil'
import { SegmentGridItem } from '../../components/segmentGridItem'
import { SegmentTableItem } from '../../components/segmentTableItem'
import { CreateDialog } from '../group/components/createDialog'

type Props = {
  rows: CustomerSegmentState[] | undefined
}

enum ViewType {
  grid = 'grid',
  table = 'table',
}

export const Overview: FC<Props> = ({ rows }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { account, shop } = useAccount()

  const [page, setPage] = useState(0)
  const rowsPerPage = 20
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [viewType, setViewType] = useLocalStorage<ViewType>('SegmentOverviewViewType', ViewType.grid)

  const [groupMenuOpen, setGroupMenuOpen] = useState(false)
  const groupMenuAnchorRef = useRef<HTMLButtonElement | null>(null)

  const [sortMenuOpen, setSortMenuOpen] = useState(false)
  const sortMenuAnchorRef = useRef<HTMLButtonElement | null>(null)

  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const { segmentGroupsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: segmentGroups } = useCollectionSubscription(query(segmentGroupsRef, orderBy('createdAt', 'desc')))

  const filteredCustomerSegments = useMemo(() => {
    if (!rows) return undefined
    return rows.filter((row) => !searchInput || row.name.toLowerCase().includes(searchInput.toLowerCase()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, searchInput])

  const { sortedData: sortedFilteredCustomerSegments, handleSort } = useSort<CustomerSegmentState>(filteredCustomerSegments || [])

  const handleCreate = () => {
    if (!rows) return
    if (!hasProAccess(shop) && rows.length >= shop.billingPlan.maxCustomerSegmentCount) {
      setUpgradeRecommendDialogOpen(true)
      return
    }
    navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE)
  }

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: filteredCustomerSegments?.map((row) => {
      return {
        segmentName: extractTextFromTextWithIcon(row.name),
        count: row.metrics.count,
        repeaterRate: row.metrics.repeaterRate.toFixed(3),
        ltv: row.metrics.ltv,
        avgOrderValue: row.metrics.avgOrderValue,
        avgOrderCount: row.metrics.avgOrderCount.toFixed(2),
        avgDaysBetweenOrders: row.metrics.avgDaysBetweenOrders.toFixed(1),
      }
    }),
    headers: [
      t('features.customerSegments.overview.name'),
      t('common.customerMetrics.title', { context: 'count' }),
      t('common.customerMetrics.title', { context: 'repeaterRate' }),
      t('common.customerMetrics.title', { context: 'ltv' }),
      t('common.customerMetrics.title', { context: 'avgOrderValue' }),
      t('common.customerMetrics.title', { context: 'avgOrderCount' }),
      t('common.customerMetrics.title', { context: 'avgDaysBetweenOrders' }),
    ],
    fileName: t('features.customerSegments.overview.downloadFileName', { date: formatDate(dayjs(), i18n.language) }),
    page: 'customerSegments',
  })

  const sortOptions: { name: string; target: NestedKeyOf<CustomerSegmentState>; orderBy: sortType }[] = [
    { name: t('features.customerSegments.overview.sortMenuItem.createdAtLatest'), target: 'createdAt', orderBy: 'desc' },
    { name: t('features.customerSegments.overview.sortMenuItem.createdAtOldest'), target: 'createdAt', orderBy: 'asc' },
    { name: t('features.customerSegments.overview.sortMenuItem.customersHighToLow'), target: 'metrics.count', orderBy: 'desc' },
    { name: t('features.customerSegments.overview.sortMenuItem.customersLowToHigh'), target: 'metrics.count', orderBy: 'asc' },
    { name: t('features.customerSegments.overview.sortMenuItem.lifetimeValueHighToLow'), target: 'metrics.ltv', orderBy: 'desc' },
    { name: t('features.customerSegments.overview.sortMenuItem.lifetimeValueLowToHigh'), target: 'metrics.ltv', orderBy: 'asc' },
  ]

  const [selectedOption, setSelectedOption] = useState(sortOptions[0].name)

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='24px'>
        <PageHeader title={t('features.customerSegments.overview.title')} marginBottom='0px' guideType='CustomerSegmentOverview' />

        <Box display='flex' alignItems='center'>
          <ToggleButtonGroup
            value={viewType}
            size='small'
            exclusive
            onChange={(_, v) => {
              if (v === null) return
              setViewType(v)
            }}
            sx={{ marginRight: '8px' }}
          >
            <ToggleButton value={ViewType.grid}>
              <GridViewOutlinedIcon fontSize='small' />
            </ToggleButton>
            <ToggleButton value={ViewType.table}>
              <FormatListBulletedOutlinedIcon fontSize='small' />
            </ToggleButton>
          </ToggleButtonGroup>

          <Tooltip title={t('features.customerSegments.overview.sort')} placement='top'>
            <span>
              <IconButton
                color='inherit'
                size='small'
                disabled={!filteredCustomerSegments || filteredCustomerSegments.length === 0}
                ref={sortMenuAnchorRef}
                onClick={() => setSortMenuOpen(true)}
                sx={{ marginRight: '4px' }}
              >
                <Badge
                  color='primary'
                  variant='dot'
                  invisible={selectedOption === t('features.customerSegments.overview.sortMenuItem.createdAtLatest')}
                >
                  <SwapVertIcon />
                </Badge>
              </IconButton>
            </span>
          </Tooltip>
          <Menu anchorEl={sortMenuAnchorRef.current} open={sortMenuOpen} onClose={() => setSortMenuOpen(false)}>
            <MenuList dense sx={{ padding: '0px' }}>
              {sortOptions.map((option) => (
                <MenuItem
                  key={option.name}
                  selected={option.name === selectedOption}
                  onClick={() => {
                    handleSort(option.target, option.orderBy)
                    setSortMenuOpen(false)
                    setSelectedOption(option.name)
                  }}
                >
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          <Box display='flex' alignItems='center'>
            <Tooltip title={t('features.customerSegments.overview.search')} placement='top'>
              <IconButton color='inherit' size='small' onClick={() => setSearchOpen(!searchOpen)} sx={{ marginRight: '4px' }}>
                <Badge color='primary' variant='dot' invisible={searchInput.length === 0}>
                  <SearchIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Collapse orientation='horizontal' in={searchOpen}>
              <TextField variant='standard' autoFocus size='small' onChange={(e) => setSearchInput(e.target.value)} />
            </Collapse>
          </Box>

          <Tooltip title={t('features.customerSegments.overview.groups')} placement='top'>
            <span>
              <IconButton
                color='inherit'
                size='small'
                disabled={!rows || rows.length === 0}
                ref={groupMenuAnchorRef}
                onClick={() => setGroupMenuOpen(true)}
                sx={{ marginRight: '4px' }}
              >
                <FolderOpenOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Menu anchorEl={groupMenuAnchorRef.current} open={groupMenuOpen} onClose={() => setGroupMenuOpen(false)}>
            <MenuList dense sx={{ padding: '0px' }}>
              {segmentGroups &&
                segmentGroups.map((group) => (
                  <MenuItem
                    key={group.ref.id}
                    onClick={() => {
                      const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_GROUP, { id: group.ref.id })
                      navigate(to)
                    }}
                  >
                    <ListItemText>{group.name}</ListItemText>
                  </MenuItem>
                ))}
              {segmentGroups && segmentGroups.length > 0 && <Divider />}
              <MenuItem
                onClick={() => {
                  setCreateGroupDialogOpen(true)
                  setGroupMenuOpen(false)
                }}
              >
                <ListItemText>{t('features.customerSegments.overview.createGroup')}</ListItemText>
              </MenuItem>
            </MenuList>
          </Menu>

          <Tooltip title={t('features.customerSegments.overview.download')} placement='top'>
            <span>
              <IconButton
                color='inherit'
                size='small'
                disabled={!filteredCustomerSegments || filteredCustomerSegments.length === 0 || downloadLoading}
                onClick={() => {
                  if (hasProAccess(shop)) {
                    handleDownload()
                    return
                  }
                  setUpgradeRecommendDialogOpen(true)
                }}
                sx={{ marginRight: '12px' }}
              >
                <FileDownloadOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Button variant='contained' disabled={!rows} onClick={handleCreate}>
            {t('features.customerSegments.overview.create')}
          </Button>
        </Box>
      </Box>

      {rows &&
        (rows.length === 0 ? (
          <EmptyState
            title={t('features.customerSegments.overview.segmentIsEmpty')}
            buttonText={t('features.customerSegments.overview.create')}
            buttonOnClick={handleCreate}
          />
        ) : (
          filteredCustomerSegments?.length === 0 && (
            <EmptyState title={t('features.customerSegments.overview.searchResultZero', { input: searchInput })} />
          )
        ))}

      {viewType === ViewType.table &&
        (!filteredCustomerSegments ? (
          <SkeletonTable rowCount={3} columnCount={9} />
        ) : (
          filteredCustomerSegments.length > 0 && (
            <TableContainer>
              <Table sx={{ minWidth: '650px' }}>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell />
                    <TableCell>{t('features.customerSegments.overview.name')}</TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip metricsKey='count' element={<Box>{t('common.customerMetrics.title', { context: 'count' })}</Box>} />
                    </TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip
                        metricsKey='repeaterRate'
                        element={<Box>{t('common.customerMetrics.title', { context: 'repeaterRate' })}</Box>}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip metricsKey='ltv' element={<Box>{t('common.customerMetrics.title', { context: 'ltv' })}</Box>} />
                    </TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip
                        metricsKey='avgOrderValue'
                        element={<Box>{t('common.customerMetrics.title', { context: 'avgOrderValue' })}</Box>}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip
                        metricsKey='avgOrderCount'
                        element={<Box>{t('common.customerMetrics.title', { context: 'avgOrderCount' })}</Box>}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <MetricsTooltip
                        metricsKey='avgDaysBetweenOrders'
                        element={<Box>{t('common.customerMetrics.title', { context: 'avgDaysBetweenOrders' })}</Box>}
                      />
                    </TableCell>
                    <TableCell align='right' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedFilteredCustomerSegments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <SegmentTableItem key={row.id} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        ))}

      {viewType === ViewType.grid && (
        <Grid container spacing={3} alignItems='stretch' sx={{ padding: '8px 4px' }}>
          {!filteredCustomerSegments
            ? Array.from(new Array(8)).map((_, i) => (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                  <Card variant='outlined' sx={{ borderRadius: '12px' }}>
                    <CardActionArea>
                      <CardContent>
                        <Skeleton width={30} height={50} animation='wave' />
                        <Skeleton width='60%' height={16} animation='wave' />
                        <Skeleton width='100%' height={16} animation='wave' />
                        <Skeleton width='100%' height={16} animation='wave' />
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            : sortedFilteredCustomerSegments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <Grid key={row.name} item xs={12} sm={6} md={4} lg={3}>
                  <SegmentGridItem row={row} />
                </Grid>
              ))}
        </Grid>
      )}

      {filteredCustomerSegments && filteredCustomerSegments.length > 0 && (
        <Box display='flex' justifyContent='center'>
          <Pagination
            shape='rounded'
            count={filteredCustomerSegments ? Math.ceil(filteredCustomerSegments.length / rowsPerPage) : 0}
            page={page + 1}
            onChange={(_, v) => setPage(v - 1)}
            sx={{ marginY: '20px' }}
          />
        </Box>
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentOverview'
        />
      )}

      {createGroupDialogOpen && <CreateDialog open={createGroupDialogOpen} handleClose={() => setCreateGroupDialogOpen(false)} />}
    </>
  )
}
