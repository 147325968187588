import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { getImagePath } from 'utils/imageUtil'

type Props = {
  open: boolean
  handleClose: () => void
  referrer: string // page name
}

// UpgradeRecommendDialog is used when users on the free plan exceed the maximum limit of resources they have created.
export const UpgradeRecommendDialog: FC<Props> = ({ open, handleClose, referrer }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  useEffect(() => {
    dispatch('OpenUpgradeRecommendDialog', { referrer })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box>
        <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '12px', right: '12px' }}>
          <CloseIcon fontSize='small' />
        </IconButton>

        <Grid container alignItems='stretch'>
          <Grid item xs={6.5} sx={{ padding: '32px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography fontSize='20px' fontWeight='bold' marginBottom='8px'>
                  {t('common.upgradeRecommendDialog.title')}
                </Typography>
                <Typography color='text.secondary' fontSize={i18n.language === 'ja' ? '14px' : '16px'}>
                  {t('common.upgradeRecommendDialog.description')}
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Paper variant='outlined' sx={{ padding: '20px 16px', background: 'transparent' }}>
                  <Grid container spacing={2}>
                    {['segment', 'feature', 'history', 'download'].map((feature, i) => (
                      <Grid key={i} item xs={12}>
                        <Typography fontWeight='bold' marginBottom='4px'>
                          {t('common.upgradeRecommendDialog.title', { context: feature })}
                        </Typography>
                        <Typography color='text.secondary' fontSize='14px'>
                          {t('common.upgradeRecommendDialog.description', { context: feature })}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Button
                      onClick={() => {
                        dispatch('ClickCheckPlan', { referrer })
                        navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)
                      }}
                      variant='contained'
                      fullWidth
                    >
                      {t('common.upgradeRecommendDialog.submit')}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      onClick={() => {
                        if (window.ChannelIO) {
                          window.ChannelIO('show') // Open ChannelTalk
                        }
                        handleClose()
                      }}
                      variant='outlined'
                      fullWidth
                    >
                      {t('common.upgradeRecommendDialog.talk')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={5.5} sx={{ backgroundImage: `url(${getImagePath('upgrade_bg.png')})`, backgroundSize: 'cover' }}>
            <Box display='flex' justifyContent='center' height='100%'>
              <img src={getImagePath('upgrade.svg')} alt='upgrade-recommend-dialog' width='75%' />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}
