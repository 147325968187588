import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, ListItemText, ListSubheader, Menu, MenuItem, MenuList, Skeleton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { GuideIcon } from 'components/guideIcon'
import { orderBy, query } from 'firebase/firestore'
import { InsightDimension, InsightReportType } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollection } from 'hooks/useFirestoreData'
import { useActionTracker } from 'hooks/useMixpanel'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { ReportTypeIcon } from '../components/reportTypeIcon'
import { InsightFilterState } from '../types/insight'
import { InsightCategory, InsightDimensionProfile, insightDimensions } from '../types/insightDimensions'
import { HistogramInsight } from './components/histogramInsight'
import { InsightFilter } from './components/insightFilter'
import { MonthlyInsight } from './components/monthlyInsight'
import { NthOrderInsight } from './components/nthOrderInsight'
import { OrderCountInsight } from './components/orderCountInsight'
import { PopularityInsight } from './components/popularityInsight'

export const Detail = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { dispatch } = useActionTracker()
  const { account } = useAccount()

  const params = useParams()
  const searchParams = new URLSearchParams(location.search)

  const viewTypeParam = searchParams.get('reportType') as InsightReportType | undefined

  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))
  const [dimensionMenuOpen, setDimensionMenuOpen] = useState(false)
  const dimensionMenuAnchorRef = useRef<HTMLButtonElement | null>(null)

  const [insightFilter, setInsightFilter] = useState<InsightFilterState>({
    customerSegmentId: searchParams.get('customer_segment_id') || undefined,
    startDate: searchParams.get('start_date') || undefined,
    endDate: searchParams.get('end_date') || undefined,
  })

  const insightDimension: InsightDimension | undefined = useMemo(() => {
    return params.id as InsightDimension
  }, [params.id])

  const dimensionProfile: InsightDimensionProfile | undefined = useMemo(() => {
    return insightDimensions.find((d) => d.name === insightDimension)
  }, [insightDimension])

  const reportType: InsightReportType | undefined = useMemo(() => {
    if (!dimensionProfile) return
    if (viewTypeParam && dimensionProfile.reportTypes.includes(viewTypeParam)) {
      return viewTypeParam
    }
    return dimensionProfile.reportTypes[0]
  }, [dimensionProfile, viewTypeParam])

  useEffect(() => {
    if (!insightDimension || !dimensionProfile) return

    const isValidReportType = viewTypeParam && dimensionProfile.reportTypes.includes(viewTypeParam)

    if (isValidReportType) return
    const newReportType = dimensionProfile.reportTypes[0]
    if (viewTypeParam === newReportType) return

    searchParams.set('reportType', newReportType)
    if (insightFilter.customerSegmentId) searchParams.set('customer_segment_id', insightFilter.customerSegmentId)

    const to =
      generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
        id: insightDimension,
      }) + (searchParams.toString() ? `?${searchParams.toString()}` : '')
    navigate(to)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    if (!insightDimension) return
    const updateQueryParams = () => {
      if (viewTypeParam) searchParams.set('reportType', viewTypeParam)
      if (insightFilter.customerSegmentId) {
        searchParams.set('customer_segment_id', insightFilter.customerSegmentId)
      } else {
        searchParams.delete('customer_segment_id')
      }
      const to =
        generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
          id: insightDimension,
        }) + `?${searchParams.toString()}` || ''
      navigate(to)
    }
    updateQueryParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightFilter.customerSegmentId])

  useEffect(() => {
    if (!insightDimension || !reportType || !insightFilter.customerSegmentId) return
    const customerSegmentName = customerSegments?.find((cs) => cs.ref.id === insightFilter.customerSegmentId)?.name || 'all'
    dispatch('ViewInsight', { dimension: insightDimension, reportType: reportType, customerSegment: customerSegmentName })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightDimension, reportType, insightFilter.customerSegmentId])

  return (
    <>
      <Box marginBottom='20px'>
        <Box
          display='flex'
          alignItems='center'
          marginBottom='12px'
          sx={{ fontSize: '14px', color: (theme) => theme.palette.text.secondary, cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          onClick={() =>
            navigate(
              insightFilter.customerSegmentId && insightFilter.customerSegmentId !== ''
                ? `${AUTHORIZED_ROUTE.INSIGHTS}?customer_segment_id=${insightFilter.customerSegmentId}`
                : AUTHORIZED_ROUTE.INSIGHTS
            )
          }
          width='fit-content'
        >
          <ArrowBackIcon sx={{ fontSize: '14px', marginRight: '4px' }} />
          <Typography fontSize='12px'>{t('features.insights.detail.backToOverview')}</Typography>
        </Box>

        <Box display='flex' alignItems='center'>
          <Box
            display='flex'
            alignItems='center'
            width='fit-content'
            ref={dimensionMenuAnchorRef}
            sx={{
              cursor: 'pointer',
              '&:hover': { opacity: 0.7 },
            }}
            onClick={() => setDimensionMenuOpen(true)}
          >
            <Typography variant='h2' fontSize='18px' fontWeight='500'>
              {insightDimension ? t('features.insights.dimension', { context: insightDimension }) : <Skeleton />}
            </Typography>
          </Box>
          <Typography fontSize='14px' color='text.secondary' marginLeft='16px'>
            {t(`features.insights.questions.${insightDimension}_${reportType}`)}
          </Typography>
          <GuideIcon guideType={dimensionProfile ? dimensionProfile.guideType : 'Insights'} />
        </Box>

        <Menu
          anchorEl={dimensionMenuAnchorRef.current}
          open={dimensionMenuOpen}
          onClose={() => setDimensionMenuOpen(false)}
          PaperProps={{
            sx: {
              maxHeight: '480px',
            },
          }}
        >
          <MenuList dense sx={{ padding: '0px' }}>
            {Object.values(InsightCategory)
              .filter((c) => c !== InsightCategory.all)
              .map((category) => (
                <div key={category}>
                  <ListSubheader sx={{ backgroundColor: 'transparent' }}>{t('features.insights.category', { context: category })}</ListSubheader>
                  {insightDimensions
                    .filter((d) => d.category === category)
                    .map((dimension) => (
                      <MenuItem
                        key={dimension.name}
                        onClick={() => {
                          if (dimension.name === insightDimension) return
                          const newPath = generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: dimension.name })
                          const matchDimension = insightDimensions.find((d) => d.name === dimension.name)
                          if (!matchDimension) return
                          const newReportType = matchDimension.reportTypes[0]
                          if (newReportType) {
                            searchParams.set('reportType', newReportType)
                            navigate(`${newPath}?${searchParams.toString()}`)
                          }
                          setDimensionMenuOpen(false)
                        }}
                      >
                        <ListItemText>{t('features.insights.dimension', { context: dimension.name })}</ListItemText>
                      </MenuItem>
                    ))}
                </div>
              ))}
          </MenuList>
        </Menu>
      </Box>

      <Box display='flex' alignItems='center' marginBottom='16px'>
        {insightDimension && (
          <InsightFilter insightFilter={insightFilter} setInsightFilter={(filter) => setInsightFilter(filter)} insightDimension={insightDimension} />
        )}

        <ToggleButtonGroup
          value={reportType}
          size='small'
          color='primary'
          exclusive
          onChange={(_, v) => {
            if (v === null) return
            searchParams.set('reportType', v)
            if (insightFilter.customerSegmentId) searchParams.set('customer_segment_id', insightFilter.customerSegmentId)

            const to =
              insightDimension &&
              generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
                id: insightDimension,
              }) + (searchParams.toString() ? `?${searchParams.toString()}` : '')

            navigate(to)
          }}
          sx={{
            // marginLeft: '16px',
            '& .MuiToggleButtonGroup-grouped': {
              padding: '4px 12px',
            },
          }}
        >
          {dimensionProfile?.reportTypes.map((type) => (
            <ToggleButton value={type} key={type}>
              <ReportTypeIcon reportType={type} sx={{ fontSize: '16px', marginRight: '4px' }} />
              {t('features.insights.detail.reportType', { context: type })}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>

      {insightDimension && viewTypeParam === InsightReportType.popularity && (
        <PopularityInsight dimension={insightDimension} filter={insightFilter} />
      )}
      {insightDimension && viewTypeParam === InsightReportType.monthly && <MonthlyInsight dimension={insightDimension} filter={insightFilter} />}
      {insightDimension && viewTypeParam === InsightReportType.nthOrder && <NthOrderInsight dimension={insightDimension} filter={insightFilter} />}
      {insightDimension && viewTypeParam === InsightReportType.orderCount && (
        <OrderCountInsight dimension={insightDimension} filter={insightFilter} />
      )}
      {insightDimension && viewTypeParam === InsightReportType.histogram && <HistogramInsight dimension={insightDimension} filter={insightFilter} />}
    </>
  )
}
