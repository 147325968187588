import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import {
  Box,
  IconButton,
  Pagination,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import { SkeletonTable } from 'components/skeletonTable'
import dayjs from 'dayjs'
import { CustomerSegmentState, CustomerState } from 'features/customerSegments/types/types'
import { CustomerDrawer } from 'features/customers/customerDrawer'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCurrency } from 'hooks/useCurrency'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSort } from 'hooks/useSort'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'
import { EmptyState } from 'components/emptyState'

type Props = {
  customerSegment: CustomerSegmentState | undefined
  customers: CustomerState[] | undefined
  loading: boolean
}

type CustomerDrawerState = {
  open: boolean
  customer:
    | {
        id: string
        name: string
        email: string
      }
    | undefined
}

export const Customers: FC<Props> = ({ customerSegment, customers: rows, loading }) => {
  const { t, i18n } = useTranslation()
  const { formatCurrency } = useCurrency()
  const { dispatch } = useActionTracker()

  const [page, setPage] = useState(0)
  const rowsPerPage = 10
  const [customerDrawerState, setCustomerDrawerState] = useState<CustomerDrawerState>({ open: false, customer: undefined })

  const { sortedData: sortedCustomerList, sort, toggleSort } = useSort<CustomerState>(rows || [])

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: rows,
    headers: [
      t('features.customerSegments.detail.customers.customerId'),
      t('features.customerSegments.detail.customers.email'),
      t('features.customerSegments.detail.customers.name'),
      t('features.customerSegments.detail.customers.recency'),
      t('features.customerSegments.detail.customers.frequency'),
      t('features.customerSegments.detail.customers.monetary'),
    ],
    fileName: `${customerSegment?.name}_${formatDate(dayjs(), i18n.language)}`,
    page: 'customerSegment_customers',
  })

  return (
    <>
      <Paper variant='outlined' sx={{ padding: '32px', borderRadius: '12px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='24px'>
          <Typography variant='h3' sx={{ fontSize: '16px', fontWeight: 600 }}>
            {t('features.customerSegments.detail.customers.title', { date: formatDate(dayjs(), i18n.language) })}
          </Typography>

          <Tooltip title={t('features.customerSegments.detail.customers.download')} placement='top'>
            <span>
              <IconButton color='inherit' disabled={loading || !rows || rows.length === 0 || downloadLoading} onClick={handleDownload}>
                <FileDownloadOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>

        {loading || !rows ? (
          <SkeletonTable rowCount={5} columnCount={6} />
        ) : rows.length === 0 ? (
          <EmptyState title={t('features.customerSegments.detail.customers.empty')} />
        ) : (
          <>
            <TableContainer>
              <Table size='small'>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell>{t('features.customerSegments.detail.customers.name')}</TableCell>
                    <TableCell>{t('features.customerSegments.detail.customers.email')}</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sort.target === 'monetary'}
                        direction={sort.target === 'monetary' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('monetary')}
                      >
                        {t('features.customerSegments.detail.customers.monetary')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sort.target === 'frequency'}
                        direction={sort.target === 'frequency' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('frequency')}
                      >
                        {t('features.customerSegments.detail.customers.frequency')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sort.target === 'recency'}
                        direction={sort.target === 'recency' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('recency')}
                      >
                        {t('features.customerSegments.detail.customers.recency')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedCustomerList &&
                    sortedCustomerList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                          onClick={() => {
                            setCustomerDrawerState({ open: true, customer: { id: row.id, name: row.name, email: row.email } })
                            dispatch('OpenCustomerDrawer', { customerId: row.id, customerName: row.name, referrer: 'customerSegment_customers' })
                          }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ color: (theme) => theme.palette.text.secondary }}>{row.email}</TableCell>
                        <TableCell>{formatCurrency(row.monetary) || <Skeleton />}</TableCell>
                        <TableCell>{t('features.customerSegments.detail.customers.frequencyTimes', { count: row.frequency })}</TableCell>
                        <TableCell>{t('features.customerSegments.detail.customers.recencyDays', { count: row.recency })}</TableCell>
                        <TableCell align='right'>
                          <Tooltip title={t('features.customerSegments.detail.customers.viewCustomer')} placement='top'>
                            <span>
                              <IconButton
                                onClick={(e) => {
                                  setCustomerDrawerState({ open: true, customer: { id: row.id, name: row.name, email: row.email } })
                                  dispatch('OpenCustomerDrawer', {
                                    customerId: row.id,
                                    customerName: row.name,
                                    referrer: 'customerSegment_customers',
                                  })
                                }}
                              >
                                <KeyboardArrowRightIcon fontSize='small' />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display='flex' justifyContent='flex-end'>
              <Pagination
                shape='rounded'
                count={rows ? Math.ceil(rows.length / rowsPerPage) : 0}
                page={page + 1}
                onChange={(_, v) => setPage(v - 1)}
                sx={{ marginTop: '20px' }}
              />
            </Box>
          </>
        )}
      </Paper>

      <CustomerDrawer
        open={customerDrawerState.open}
        handleOpen={() => setCustomerDrawerState({ open: true, customer: customerDrawerState.customer })}
        handleClose={() => setCustomerDrawerState({ open: false, customer: undefined })}
        customer={customerDrawerState.customer}
      />
    </>
  )
}
